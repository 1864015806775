import React, { useEffect, useRef, useState } from 'react';
import Breakpoint, {
  BreakpointProvider,
  setDefaultBreakpoints,
} from 'react-socks';

import { useTranslation } from 'react-i18next';

import { Link } from '@reach/router';
import useOnclickOutside from 'react-cool-onclickoutside';
import Account from '../components/Account/Account';
import Duration from './duration';
import { useMoralis } from 'react-moralis';
import useMoralisAPI from '../../hooks/useMoralisAPI';
setDefaultBreakpoints([{ xs: 0 }, { l: 1199 }, { xl: 1200 }]);

const NavLink = (props) => (
  <Link
    {...props}
    getProps={({ isCurrent }) => {
      // the object returned here is passed to the
      // anchor element's props
      return {
        className: isCurrent ? 'active' : 'non-active',
      };
    }}
  />
);

const SubMenu = ({ title, subMenus }) => {
  const [open, setOpen] = useState(false);

  const openMenu = () => setOpen(true);
  const closeMenu = () => setOpen(false);

  const ref = useOnclickOutside(() => {
    closeMenu();
  });

  return (
    <div className="navbar-item">
      <div ref={ref}>
        <div
          className="dropdown-custom dropdown-toggle btn"
          onMouseEnter={openMenu}
          onMouseLeave={closeMenu}
        >
          {title}
          <span className="lines"></span>
          {open && (
            <div className="item-dropdown">
              <div className="dropdown" onClick={closeMenu}>
                {subMenus.map((subMenu, index) => (
                  <NavLink to={subMenu.to} key={index}>
                    {subMenu.title}
                  </NavLink>
                ))}
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

const Header = function ({ className }) {
  const { t } = useTranslation();
  const { isAuthenticated } = useMoralis();
  const { maalBalance } = useMoralisAPI();
  const [showmenu, btn_icon] = useState(false);
  const [showpop, btn_icon_pop] = useState(false);
  const [shownot, btn_icon_not] = useState(false);
  const closePop = () => {
    btn_icon_pop(false);
  };
  const closeNot = () => {
    btn_icon_not(false);
  };
  const refpop = useOnclickOutside(() => {
    closePop();
  });
  const refpopnot = useOnclickOutside(() => {
    closeNot();
  });

  useEffect(() => {
    const header = document.getElementById('myHeader');
    const totop = document.getElementById('scroll-to-top');
    const sticky = header.offsetTop;
    const scrollCallBack = window.addEventListener('scroll', () => {
      btn_icon(false);
      if (window.pageYOffset > sticky) {
        header.classList.add('sticky');
        totop.classList.add('show');
      } else {
        header.classList.remove('sticky');
        totop.classList.remove('show');
      }
      if (window.pageYOffset > sticky) {
      }
    });
    return () => {
      window.removeEventListener('scroll', scrollCallBack);
    };
  }, []);

  const subMenus = [
    {
      title: t('mint_star'),
      to: '/mint-a-star',
    },
    {
      title: t('mint_malamaal'),
      to: '/mint-malamaal',
    },
  ];

  const renderLogo = () => {
    return (
      <div className="logo p-0">
        <div className="navbar-title navbar-item">
          <NavLink to="/">
            <img src="/img/logo.png" className="img-fluid d-block" alt="#" />
            <img src="/img/logo-2.png" className="img-fluid d-3" alt="#" />
            <img src="/img/logo-3.png" className="img-fluid d-4" alt="#" />
            <img
              src="/img/logo-light.png"
              className="img-fluid d-none"
              alt="#"
            />
          </NavLink>
        </div>
      </div>
    );
  };

  if (!isAuthenticated)
    return (
      <header className={`navbar white ${className}`} id="myHeader">
        <div className="container">
          <div className="row w-100-nav p-3">{renderLogo()}</div>
        </div>
      </header>
    );

  return (
    <header className={`navbar white ${className}`} id="myHeader">
      <div className="container">
        <div className="row w-100-nav">
          {renderLogo()}
          <BreakpointProvider>
            <Breakpoint l down>
              <div style={{ color: '#727272', padding: '30px 6px', marginRight: '30px' }}>
                <span style={{ color: 'gold' }}>{maalBalance} $MAAL</span>
                < span className="lines" ></span>
              </div>
              {showmenu && (
                <div className="menu">
                  <div className="navbar-item">
                    <NavLink to="/my-stars">
                      {'My Stars'}
                      <span className="lines"></span>
                    </NavLink>
                  </div>
                  <SubMenu subMenus={subMenus} title={t('mint')} />
                  <div className="navbar-item">
                    <NavLink to="/redeem">
                      {t('redeem')}
                      <span className="lines"></span>
                    </NavLink>
                  </div>
                </div>
              )}
            </Breakpoint>

            <Breakpoint xl>
              <div className="menu">
                <div style={{ color: '#727272', padding: '30px 6px', marginRight: '30px' }}>
                  <span style={{ color: 'gold' }}>{maalBalance} $MAAL</span>
                  < span className="lines" ></span>
                </div>
                <div className="navbar-item">
                  <NavLink to="/my-stars">
                    {'My Stars'}
                    <span className="lines"></span>
                  </NavLink>
                </div>
                <SubMenu subMenus={subMenus} title={t('mint')} />
                <div className="navbar-item">
                  <NavLink to="/redeem">
                    {t('redeem')}
                    <span className="lines"></span>
                  </NavLink>
                </div>
              </div>
            </Breakpoint>
          </BreakpointProvider>
          <div className="mainside">
            <Account />
          </div>
        </div>

        <button className="nav-icon" onClick={() => btn_icon(!showmenu)}>
          <div className="menu-line white"></div>
          <div className="menu-line1 white"></div>
          <div className="menu-line2 white"></div>
        </button>
      </div>
    </header >
  );
};
export default Header;
