import React, { useEffect } from 'react';
import { Link } from '@reach/router';
import Footer from '../components/footer';
import Reveal from 'react-awesome-reveal';
import { keyframes } from '@emotion/react';
import { Trans, useTranslation } from 'react-i18next';
import { Image } from 'antd';

//IMPORT DYNAMIC STYLED COMPONENT
import { StyledHeader } from '../Styles';
import { useMoralis } from 'react-moralis';
import Account from '../components/Account/Account';
import useMoralisAPI from '../../hooks/useMoralisAPI';

const fadeInUp = keyframes`
  0% {
    opacity: 0;
    -webkit-transform: translateY(40px);
    transform: translateY(40px);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
`;

//SWITCH VARIABLE FOR PAGE STYLE
const theme = 'GREY'; //LIGHT, GREY, RETRO

const HomeThree = () => {
  const { t } = useTranslation();
  const { isAuthenticated } = useMoralis();
  const { isBinanceNetwork } = useMoralisAPI();

  return (
    <div className="greyscheme">
      <StyledHeader theme={theme} />
      {/* <section className="jumbotron no-bg" style={{ backgroundImage: `url(${'./img/background/7.png'})` }}> */}
      <section className="jumbotron no-bg">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-1 px-0"></div>
            <div className="col-lg-5 px-0">
              <Image width={380}
                preview={false} src={'/mock_data/uploads/malamaal-cover.png'} />
            </div>
            <div className="col-lg-6">
              <div className="spacer-single"></div>
              <Reveal
                className="onStep"
                keyframes={fadeInUp}
                delay={0}
                duration={600}
                triggerOnce
              >
                <h6 className="">
                  <span className="text-uppercase text-light">
                    <Trans
                      i18nKey="market_name"
                      values={{ market: 'MalaMaal' }}
                      defaults="Market"
                    />
                  </span>
                </h6>
              </Reveal>
              <div className="spacer-10"></div>
              <Reveal
                className="onStep"
                keyframes={fadeInUp}
                delay={300}
                duration={600}
                triggerOnce
              >
                <h1 className="">
                  <Trans
                    i18nKey="create_redeem_cards"
                    values={{ market: 'MalaMaal' }}
                    defaults="Mint or Redeem Cards"
                  />
                </h1>
              </Reveal>
              <Reveal
                className="onStep"
                keyframes={fadeInUp}
                delay={600}
                duration={600}
                triggerOnce
              >
                <p className="text-light">
                  <Trans
                    i18nKey="private_card_for_redeem"
                    values={{ token: '$MAAL' }}
                    defaults="Your private card for redeeming tokens"
                  />
                </p>
              </Reveal>
              <div className="spacer-10"></div>

              {isAuthenticated && isBinanceNetwork ? (
                <Reveal
                  className="onStep"
                  keyframes={fadeInUp}
                  delay={800}
                  duration={900}
                  triggerOnce
                >
                  <Link to="/mint-malamaal"
                    className="btn-main lead"
                  >
                    {t('mint')}
                  </Link>
                  <div className="mb-sm-30"></div>
                </Reveal>
              ) : (
                <Account />
              )}
              <div className="spacer-double"></div>
            </div>
          </div>
        </div>
      </section>

      <Footer />
    </div>
  );
};
export default HomeThree;
