import React, { useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import Select from '../components/Select';
import { StyledHeader } from '../Styles';
import { useMoralis, useWeb3ExecuteFunction } from 'react-moralis';
import useMoralisAPI from '../../hooks/useMoralisAPI';
import { createOptions } from '../../utils';
import { Image } from 'antd';
import config from '../../config/config';
import useToaster from "../components/Toast";
import { Spin } from 'antd'

//SWITCH VARIABLE FOR PAGE STYLE
const theme = 'GREY'; //LIGHT, GREY, RETRO

const mintPrice = 100;

const MintAStar = () => {
  const { account, Moralis } = useMoralis();
  const { maalBalance, isActive, contractABI, getMaalBalance, tokensOfOwner, getTokensOfOwner } = useMoralisAPI();
  const { t } = useTranslation();
  const [qty, setSelectedValue] = useState(0);
  const [isMinting, setIsMinting] = useState(false);
  const [tokenIdsOfOwner, setTokenIdsOfOwner] = useState(null);
  const options = createOptions(maalBalance, mintPrice);
  const { toast } = useToaster();

  const {
    data: successMintWithToken,
    error: errorMintWithToken,
    fetch: mintTokenWithMaal,
    isFetching: fetchingMintWithToken,
    isLoading: loadingMintWithToken,
  } = useWeb3ExecuteFunction({
    abi: contractABI,
    contractAddress: config[config.network].contract_css,
    functionName: 'mintWithTokens',
    params: {
      qty: qty && qty.value,
      user: account
    },

  });


  useEffect(() => {
    if (errorMintWithToken && errorMintWithToken.message) {
      if (errorMintWithToken.message.includes('User denied transaction signature')) {
        toast('error', t('user_denied_txn'));
      }
    }
  }, [errorMintWithToken]);

  useEffect(() => {
    if (tokensOfOwner && tokensOfOwner.data) {
      let tokenIds = [];
      tokensOfOwner.data.forEach((item) => {
        const tokenId = item.toNumber();
        tokenIds.push(tokenId);
      });
      setTokenIdsOfOwner(tokenIds);
    }
  }, [tokensOfOwner]);



  const handleSubmit = () => {
    // ToDo: Mint maal API call
    setIsMinting(true);
    mintTokenWithMaal(
      {
        onSuccess: async (txn) => {
          const transaction = await txn.wait();
          setIsMinting(false);
          toast('success', t('success_mint_msg'));
          getMaalBalance();
          getTokensOfOwner();
        },
        onError: (error) => {
          setIsMinting(false);
          console.log("on error", error);
        }
      }
    );
  };

  return (
    <div className="greyscheme">
      <StyledHeader theme={theme} />
      <section className="jumbotron no-bg container">
        <div>
          <div>
            <div className="de-flex justify-content-center mb30">
              <h3>{t('mint_a_star')}</h3>
            </div>
          </div>
        </div>
        {
          !isActive.isMintWithTokenActive &&
          <>
            <div className="centered">
              <div className="alert alert-warning" role="alert">
                Minting a star is not active!
              </div>
            </div>
            <div class="de-flex centered" style={{ flexDirection: 'column' }}>
              {tokenIdsOfOwner && tokenIdsOfOwner.length > 0 &&
                <div>
                  Your Token Ids
                </div>
              }
              <div>
                <h5>
                  {
                    tokenIdsOfOwner && tokenIdsOfOwner.map((item, i) => {
                      if (i === tokenIdsOfOwner.length - 1) {
                        return item;
                      } else {
                        return item + ', ';
                      }
                    })
                  }
                </h5>
              </div>
              <Image width={380}
                preview={false} src={'https://ik.imagekit.io/3sqrfownpdc4/avatars_1kKmSNVGMYTe.gif?ik-sdk-version=javascript-1.4.3&updatedAt=1642363560533'} />

            </div>
          </>

        }

        {
          isMinting &&
          <>
            <div style={{ height: '70vh', flexDirection: 'column', animation: "zoom-in-zoom-out 2s ease infinite" }} className="centered">
              <Spin size="large" />
              <div style={{ color: "#ffff" }}>
                Minting in progress
              </div>
              <Image
                style={{ animation: "zoom-in-zoom-out 2s ease infinite" }}
                width={200}
                preview={false}
                width={100}
                src="https://ik.imagekit.io/3sqrfownpdc4/mint_0X1XkIUVd.png?ik-sdk-version=javascript-1.4.3&updatedAt=1642363560200"
              />
            </div>
          </>
        }

        {
          isActive.isMintWithTokenActive && !isMinting &&
          <div className="row justify-content-center">

            <div className="col-lg-6 col-sm-6 col-xs-12 de-flex justify-content-center">
              <Image width={380}
                preview={false} src={'https://ik.imagekit.io/3sqrfownpdc4/avatars_1kKmSNVGMYTe.gif?ik-sdk-version=javascript-1.4.3&updatedAt=1642363560533'} />
            </div>
            <div className="col-lg-6 col-sm-6 col-xs-12">
              <div className="mb30">
                <div className="de-flex">
                  <div className='mt-5'>
                    {t('mint_price')}
                    <h5 className="d-balance" style={{ color: 'gold' }}>
                      {mintPrice} Maal + ${t('gas')}
                    </h5>
                  </div>
                </div>
              </div>
              <form id="form-create-item" className="form-border" action="#">
                <div className="field-set mb30">
                  <div className="dropdownSelect one mb20 w-50">
                    <Select
                      options={options}
                      value={qty}
                      noOptionsPlaceholder={
                        <Trans
                          i18nKey="mint_star_placeholder"
                          values={{ count: 100 }}
                        />
                      }
                      onChange={setSelectedValue}
                    />
                  </div>
                  <input
                    type="button"
                    onClick={handleSubmit}
                    disabled={!qty}
                    id="submit"
                    className="btn-main"
                    value={t('mint')}
                  />
                </div>
              </form>
              {
                tokenIdsOfOwner && tokenIdsOfOwner.length > 0 &&
                <div>
                  <div>
                    Your Token Ids
                  </div>
                  <h5>
                    {
                      tokenIdsOfOwner && tokenIdsOfOwner.map((item, i) => {
                        if (i === tokenIdsOfOwner.length - 1) {
                          return item;
                        } else {
                          return item + ', ';
                        }
                      })
                    }
                  </h5>
                </div>

              }

            </div>
          </div>
        }


      </section>
    </div>
  );
};

export default MintAStar;
