import React, { memo, useState } from 'react';
import styled from "styled-components";
import { Skeleton, Tooltip } from 'antd';
import axios from 'axios';
import useMoralisAPI from "../../hooks/useMoralisAPI";
import { InfoCircleOutlined } from '@ant-design/icons';


const Outer = styled.div`
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
  overflow: hidden;
  border-radius: 8px;
`;

//react functional component
const NftMintCard = ({ nft, className = 'd-item col-lg-3 col-md-6 col-sm-6 col-xs-12 mb-4', clockTop = true, height, onSelectNft }) => {

    const [selected, setCardSelected] = useState(false);
    const { getMalaMaalNFTs } = useMoralisAPI();


    function toggleSelect(nft) {
        if (nft.image_url) {
            setCardSelected(!selected);
            onSelectNft(nft, selected);
        }
    }

    // function refreshMetadata(nft) {
    //     axios.get(`https://deep-index.moralis.io/api/v2/nft/0xde40b01f7da2ecc41b9e01e5ff651d3ccf6335b8/${nft.token_id}/metadata/resync?chain=bsc%20testnet&flag=metadata`,
    //         {
    //             headers: {
    //                 'accept': '*/*',
    //                 'X-API-Key': 'brBeDgEVvexKhMCAfALnwPvdSqnr4LdChfXdqCeAT9bjInFQPqEabB47v6GBIlfE'
    //             }
    //         }
    //     )
    //         .then(res => {
    //             // call get NFT balance
    //             getMalaMaalNFTs();
    //         })
    // }

    return (
        <div className={className}>
            <div className="nft__item m-0" onClick={() => toggleSelect(nft)}>
                <div className='icontype'><i className="fa fa-bookmark"></i></div>
                {
                    nft.image_url ?
                        <div className="author_list_pp">
                            <span>
                                <>
                                    {
                                        selected ?
                                            <img className="lazy"
                                                src="https://img.icons8.com/stickers/100/000000/checked-checkbox.png" alt="select" />
                                            :
                                            <img className="lazy"
                                                src="https://img.icons8.com/color/40/000000/unchecked-checkbox--v1.png" alt="unselect" />

                                    }
                                </>
                            </span>
                        </div>
                        :
                        <Tooltip title="Try refreshing after some time!" color={'#aa34ff'} key={'#aa34ff'}>
                            <InfoCircleOutlined />
                        </Tooltip>
                }

                <div className="nft__item_wrap" style={{ height: `${height}px` }}>
                    <Outer>
                        {
                            nft.image_url ?
                                <span>
                                    <video id="vid" width="100%" height="250" autoPlay loop>
                                        <source src={nft.image_url} />
                                    </video>
                                </span>
                                :
                                <Skeleton.Image />
                        }
                    </Outer>
                </div>
                <div className="nft__item_info">
                    <span>
                        <h4>{nft.name} #{nft.token_id}</h4>
                    </span>
                    <div className="nft__item_like">
                        <span></span>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default memo(NftMintCard);
