import React from 'react';
// import { Link } from '@reach/router';

const footer = () => (
    <footer className="footer-light">
        {/* <div className="container">
            <div className="row">
                <div className="col-md-3 col-sm-6 col-xs-1">
                    <div className="widget">
                        <h5>CSS Club</h5>
                        <ul>
                            <li><Link to="">All Stars</Link></li>
                        </ul>
                    </div>
                </div>
                <div className="col-md-3 col-sm-6 col-xs-1">
                    <div className="widget">
                        <h5>Resources</h5>
                        <ul>
                            <li><Link to="">Help Center</Link></li>
                            <li><Link to="">Discord</Link></li>
                        </ul>
                    </div>
                </div>
                <div className="col-md-3 col-sm-6 col-xs-1">
                    <div className="widget">
                        <h5>Community</h5>
                        <ul>
                            <li><Link to="">Blog</Link></li>
                        </ul>
                    </div>
                </div>
                <div className="col-md-3 col-sm-6 col-xs-1">
                    <div className="widget">
                        <h5>Newsletter</h5>
                        <p>Signup for our newsletter to get the latest news in your inbox.</p>
                        <form action="#" className="row form-dark" id="form_subscribe" method="post" name="form_subscribe">
                            <div className="col text-center">
                                <input className="form-control" id="txt_subscribe" name="txt_subscribe" placeholder="enter your email" type="text" />
                                <Link to="" id="btn-subscribe">
                                    <i className="arrow_right bg-color-secondary"></i>
                                </Link>
                                <div className="clearfix"></div>
                            </div>
                        </form>
                        <div className="spacer-10"></div>
                        <small>Your email is safe with us. We don't spam.</small>
                    </div>
                </div>
            </div>
        </div> */}
        <div className="subfooter">
            <div className="container">
                <div className="row">
                    <div className="col-md-12">
                        <div className="de-flex">
                            <div className="de-flex-col">
                                <span onClick={() => window.open("", "_self")}>
                                    <img alt="" className="f-logo d-1" src="./img/logo.png" />
                                    <img alt="" className="f-logo d-3" src="./img/logo-2-light.png" />
                                    <img alt="" className="f-logo d-4" src="./img/logo-3.png" />
                                    <span className="copy">&copy; Copyright 2022 - CSS Club</span>
                                </span>
                            </div>
                            <div className="de-flex-col">
                                <div className="social-icons">
                                    <span className='mr10 text-light' onClick={() => window.open("https://discord.gg/cssclub", "_blank")}>Discord</span>
                                    <span onClick={() => window.open("https://twitter.com/cssclubnft", "_blank")}><i className="fa fa-twitter fa-lg"></i></span>
                                    <span onClick={() => window.open("https://instagram.com/cssclubnft", "_blank")}><i className="fa fa-instagram fa-lg"></i></span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </footer>
);
export default footer;