import React, { memo, useEffect, useState } from 'react';
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { Image } from 'antd';

import { keyframes } from "@emotion/react";
//IMPORT DYNAMIC STYLED COMPONENT
import { StyledHeader } from '../Styles';
import { useWeb3ExecuteFunction } from 'react-moralis';
import { useMoralis } from "react-moralis";
import MAALA_MAAL_ABI from "../../abi/abi_maalamaal.json";
import config from '../../config/config';
import { Spin, Tooltip } from "antd";
import useMoralisAPI from "../../hooks/useMoralisAPI";
import useToaster from '../../components/components/Toast';
import NftMintCard from "../components/NftMintCard";

const Button = styled.button`
  width: 230px !important;
`;

const fadeInUp = keyframes`
  0% {
    opacity: 0;
    -webkit-transform: translateY(40px);
    transform: translateY(40px);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
`;

//SWITCH VARIABLE FOR PAGE STYLE
const theme = 'GREY'; //LIGHT, GREY, RETRO

const Redeem = () => {
  const { Moralis, isAuthenticated, chainId, account } = useMoralis();
  const { toast } = useToaster();
  const { malaMaal, cardMapper, isActive, getMaalBalance, getMalaMaalNFTs } = useMoralisAPI();
  const { t } = useTranslation();
  const [malamaalNFTs, setMalamalNFTs] = useState([]);
  const [qty, setQty] = useState(1);
  const [isRedeeming, setIsRedeeming] = useState(false);
  const [redeemSuccess, setRedeemSuccess] = useState(false);
  const [tokenIds, setTokenIds] = useState([]); //Smart Contract ABI here
  const [totalMaalClaim, setTotalMaalClaim] = useState(0);
  const [totalSelected, setTotalSelected] = useState(0);
  const [maalamaalContractABI, setMaalaamaalContractABI] = useState(null); //Smart Contract ABI here
  const { tokenIdOptions } = useMoralisAPI();

  const { data: dataRedeem, error: errorRedeem, fetch: redeemMaal,
    isFetching: isFetchingRedeem, isLoading: isLoadRedeem } = useWeb3ExecuteFunction({
      abi: maalamaalContractABI,
      contractAddress: config[config.network].contract_maalaamal,
      functionName: "redeemToken",
      params: {
        tokenIds: tokenIds
      }
    });


  function setContractABIJson() {
    setMaalaamaalContractABI(MAALA_MAAL_ABI);
  }

  useEffect(() => {
    if (isAuthenticated) {
      console.log(chainId);
      setContractABIJson();
    }
  }, [isAuthenticated]);

  useEffect(() => {
    if (malaMaal.data && malaMaal.data.length > 0) {
      const data = JSON.parse(JSON.stringify(malaMaal.data))
      setMalamalNFTs(data);
    }
  }, [malaMaal]);

  useEffect(() => {
    if (errorRedeem && errorRedeem.message) {
      if (errorRedeem.message.includes('User denied transaction signature')) {
        toast('error', t('user_denied_txn'));
      }
    }
    if (errorRedeem && errorRedeem.message && errorRedeem.message.includes('owner query for nonexistent token')) {
      toast('error', t('user_already_redeemed'));
    }
    if (errorRedeem && errorRedeem.data && errorRedeem.data.message && errorRedeem.data.message.includes('owner query for nonexistent token')) {
      toast('error', t('user_already_redeemed'));
    }
  }, [errorRedeem]);

  useEffect(() => {
    if (redeemSuccess) {
      toast('success', t('success_redeem_msg'));
    }
  }, [redeemSuccess]);


  function onSelectNft(nft, selected) {
    if (!selected) {
      const total = totalMaalClaim + cardMapper[nft.type];
      setTotalMaalClaim(total);
      setTotalSelected(totalSelected + 1);
      tokenIds.push(nft.token_id);
      setTokenIds(tokenIds);
    } else {
      const total = totalMaalClaim - cardMapper[nft.type];
      setTotalMaalClaim(total);
      setTotalSelected(totalSelected - 1);
      tokenIds.splice(tokenIds.indexOf(nft.token_id), 1);
      setTokenIds(tokenIds);
    }
  }

  const redeemMaalTokens = () => {
    if (totalSelected > 0) {
      setIsRedeeming(true);
      redeemMaal(
        {
          onSuccess: async (txn) => {
            const transaction = await txn.wait();
            setIsRedeeming(false);
            setTotalMaalClaim(0);
            setTotalSelected(0);
            getMaalBalance();
            setRedeemSuccess(true);
            setTimeout(() => {
              getMalaMaalNFTs();
            }, 2000);
            setTokenIds([]);

          },
          onError: (error) => {
            console.log("on error", error);
            setIsRedeeming(false);
            setTotalMaalClaim(0);
            setTotalSelected(0);
            setTokenIds([]);
          }
        });
    } else {
      toast('error', t('select_an_item_to_redeem'))
    }

  }

  return (
    <div className="greyscheme">
      <StyledHeader theme={theme} />

      <section className="container jumbotron no-bg">

        {
          <div>
            <div className="mb30">
              <div className="de-flex justify-content-center">
                <h3>{t('redeem_maal')}</h3>
              </div>
              {
                isAuthenticated && account && isActive.isRedeemActive && !isRedeeming && !malaMaal.isLoading && malamaalNFTs.length > 4 &&
                <div className="row flex-column">
                  <div className="de-flex justify-content-center align-items-center">
                    <Button onClick={redeemMaalTokens} style={{ minWidth: '300px' }} className='btn-main lead mb-2 mr20'>Redeem  {totalMaalClaim} $MAAL({totalSelected})</Button>
                  </div>
                </div>
              }
            </div>
          </div>
        }

        {
          isAuthenticated && account && isActive.isRedeemActive && malamaalNFTs.length === 0 &&
          <div className="de-flex justify-content-center">
            Nothing to Redeem!
          </div>
        }

        {
          isAuthenticated && isActive.isRedeemActive && !malaMaal.isLoading && isRedeeming &&
          <>
            <div style={{ height: '50vh', flexDirection: 'column', animation: "zoom-in-zoom-out 2s ease infinite" }} className="centered">
              <div style={{ color: "#ffff" }}>
                <div className="de-flex justify-content-center mb20">
                  REDEEMING...
                </div>
                <Image style={{ borderRadius: '50%' }} src={'https://i.kym-cdn.com/photos/images/original/001/001/589/b70.gif'} preview={false} />
              </div>
            </div>

          </>
        }

        {
          isAuthenticated && isActive.isRedeemActive && malaMaal.isLoading &&
          <>
            <div style={{ height: '50vh', flexDirection: 'column', animation: "zoom-in-zoom-out 2s ease infinite" }} className="centered">
              <Spin size="large" />
              <div style={{ color: "#ffff" }}>
                Fetching all cards...
              </div>
            </div>

          </>
        }

        {
          isAuthenticated && account && isActive.isRedeemActive && !isRedeeming && !malaMaal.isLoading && malamaalNFTs.length > 0 &&
          <>
            <div className="row">
              {
                malamaalNFTs.map((nft, i) => {
                  return <NftMintCard nft={{ ...nft, id: i }} key={nft.token_id} onSelectNft={onSelectNft} />
                })
              }
            </div>
            <div className="row flex-column">
              <div className="de-flex justify-content-center align-items-center">
                <Button onClick={redeemMaalTokens} style={{ minWidth: '300px' }} className='btn-main lead mb-2 mr20'>Redeem  {totalMaalClaim} $MAAL({totalSelected})</Button>
              </div>
            </div>
          </>
        }

        {
          !isActive.isRedeemActive && !isRedeeming &&
          <div style={{ height: '70vh' }} className="centered">
            <div className="alert alert-warning" role="alert">
              Redeeming is not active!
            </div>
          </div>
        }

      </section>
    </div>
  );
}
export default Redeem;
