import React, { memo, useEffect, useState } from 'react';
import { useTranslation, Trans } from 'react-i18next';
// import SliderCarouselSingleRedux from '../components/SliderCarouselSingleRedux';
import Footer from '../components/footer';
import Reveal from 'react-awesome-reveal';
import { keyframes } from "@emotion/react";
//IMPORT DYNAMIC STYLED COMPONENT
import { StyledHeader } from '../Styles';
import { useWeb3ExecuteFunction } from 'react-moralis';
import Select from 'react-select';
import { useMoralis } from "react-moralis";
import CSS_ABI from "../../abi/abi_css.json";
import MAALA_MAAL_ABI from "../../abi/abi_maalamaal.json";
import MAAL_ABI from "../../abi/abi_maal.json";
import config from '../../config/config';
import { Spin } from "antd";
import useMoralisAPI from "../../hooks/useMoralisAPI";
import useToaster from "../components/Toast";


const fadeInUp = keyframes`
  0% {
    opacity: 0;
    -webkit-transform: translateY(40px);
    transform: translateY(40px);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
`;

//SWITCH VARIABLE FOR PAGE STYLE
const theme = 'GREY'; //LIGHT, GREY, RETRO


const Mint = () => {
  const { t } = useTranslation();
  const [cardType, setCardType] = useState('silver');
  const [qty, setQty] = useState(1);
  const { isAuthenticated, account } = useMoralis();
  const { contractABI, malaMaalContractABI, maalContractABI, isApproved, maalBalance } = useMoralisAPI();
  const { toast } = useToaster();
  const { data: dataMintWithMaal, error: errorMintWithMaal, fetch: mintWithMaal,
    isFetching: isFetchingMintWithMaal, isLoading: isLoadMintWithMaal } = useWeb3ExecuteFunction({
      abi: contractABI,
      contractAddress: config[config.network].contract_css,
      functionName: "mintWithTokens",
      params: {
        qty: 0
      },
      msgValue: 0
    });

  const { data: dataMalamal, error: errorMalamal, fetch: mintMalamaal,
    isFetching: isFetchingMalamal, isLoading: isLoadMalamal } = useWeb3ExecuteFunction({
      abi: malaMaalContractABI,
      contractAddress: config[config.network].contract_maalaamal,
      functionName: "mint",
      params: {
        qty: 1,
        cardType: cardType
      },
      msgValue: 0
    });

  const { data: successAproveMaal, error: errorApproveMaal, fetch: aproveMaal,
    isFetching: isFetchingAproveMaal, isLoading: isLoadingApprove } = useWeb3ExecuteFunction({
      abi: maalContractABI,
      contractAddress: config[config.network].contract_maal,
      functionName: "approve",
      params: {
        spender: config[config.network].contract_maalaamal,
        amount: "600000000000000000000"
      }
    });

  const qtyOptions = [
    { value: 1, label: '1' },
    { value: 2, label: '2' },
    { value: 3, label: '3' },
  ];
  const cardOptions = [
    { value: 'silver', label: t('silver') },
    { value: 'gold', label: t('gold') },
    { value: 'platinum', label: t('platinum') },
    { value: 'black', label: t('black') },
  ];

  function checkApproveAndMintMaal() {
    if (isApproved) {
      mintMalamaal();
    } else {
      aproveMaal();
    }
  }

  useEffect(() => {
    if (errorMalamal) {
      toast('error', 'Error in mint malamal');
    }
    if (errorApproveMaal) {
      toast('error', 'Error in approve mal');
    }
    if (errorMintWithMaal) {
      toast('error', 'Error in mint with mal');
    }
  }, [errorMalamal, errorMintWithMaal, errorApproveMaal, successAproveMaal])


  return (
    <div className="greyscheme">
      <StyledHeader theme={theme} />
      {/* <section className="jumbotron no-bg" style={{ backgroundImage: `url(${'./img/background/7.png'})` }}> */}
      <section className="jumbotron no-bg">
        {
          isAuthenticated && account ?
            <div className='container'>
              <div className='row align-items-center'>
                <div className='col-lg-6'>
                  <div className="spacer-single"></div>
                  <Reveal className='onStep' keyframes={fadeInUp} delay={0} duration={600} triggerOnce>
                    <h6 className="">
                      <span className="text-uppercase text-light">
                        <Trans i18nKey="create_token" values={{ market: 'MalaMaal' }} />
                      </span>
                    </h6>
                  </Reveal>
                  <div className="spacer-double"></div>
                  <div className="spacer-double"></div>
                  <div className="spacer-double"></div>
                  <div className="spacer-double"></div>
                  <div className="spacer-double"></div>
                </div>
                {
                  isFetchingMalamal || isFetchingAproveMaal ? <Spin /> : ''
                }
                <div style={{ color: '#fff' }}>
                  <Trans i18nKey="your_mall_balance" values={{ token: 'MAAL', maalBalance }} />
                </div>
                <div className='dropdownSelect one'>
                  <Select
                    defaultValue={qty}
                    options={qtyOptions}
                    onChange={(e) => { setQty(e.value) }}
                  />
                </div>
                <div className='dropdownSelect one'>
                  <Select
                    defaultValue={cardType}
                    options={cardOptions}
                    onChange={(e) => { setCardType(e.value) }}
                  />
                </div>
                <button onClick={() => checkApproveAndMintMaal()}>
                  <Trans i18nKey="mint_maal_pass" values={{ token: 'Maal' }} />
                </button>
                <button onClick={() => mintWithMaal()}>
                  <Trans i18nKey="mint_with" values={{ token: 'Maal' }} />
                </button>
              </div>
            </div>
            :
            <>
              <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '50vh' }}>
                {t('please_connect_wallet')}
              </div>
            </>
        }

      </section >
      <Footer />
    </div >
  );
}
export default Mint;
