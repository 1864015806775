import React, { useEffect, useState } from 'react';
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { Image } from 'antd';

//IMPORT DYNAMIC STYLED COMPONENT
import { StyledHeader } from '../Styles';
import { useWeb3ExecuteFunction } from 'react-moralis';
import { useMoralis } from "react-moralis";
import CSSC_ABI from "../../abi/abi_css.json";
import config from '../../config/config';
import { Spin } from "antd";
import useMoralisAPI from "../../hooks/useMoralisAPI";
import useToaster from '../../components/components/Toast';



const Outer = styled.div`
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
  overflow: hidden;
  border-radius: 8px;
`;

const Button = styled.button`
  width: 200px !important;
`;

//SWITCH VARIABLE FOR PAGE STYLE
const theme = 'GREY'; //LIGHT, GREY, RETRO

const dailyMaal = {
    'HOUSE': 5,
    'BOX': 2.5,
    'BALCONY': 1.5,
    'STALL': 1
}

const MyStars = () => {
    const { Moralis, isAuthenticated, chainId, account } = useMoralis();
    const { toast } = useToaster();
    const { myStars, getMaalBalance, getMyStars, rewards, getClaimableReward } = useMoralisAPI();
    const { t } = useTranslation();
    const [myStarsNFTs, setMyStarsNFTs] = useState([]);
    const [totalEarningDaily, setTotalEarningDaily] = useState(0);
    const [isClaiming, setIsClaiming] = useState(false);
    const [claimableRewards, setClaimableRewards] = useState(0);


    const { data: rewardsSuccess, error: errorRewards, fetch: getRewards,
        isFetching: isFetchingRewards, isLoading: isLoadingRewards } = useWeb3ExecuteFunction({
            abi: CSSC_ABI,
            contractAddress: config[config.network].contract_css,
            functionName: "getReward"
        });

    function getMaalRewards() {
        setIsClaiming(true);
        getRewards(
            {
                onSuccess: async (txn) => {
                    const transaction = await txn.wait();
                    toast('success', t('success_claim'));
                    setIsClaiming(false);
                    getClaimableReward();

                },
                onError: (error) => {
                    console.log("on error", error);
                    setIsClaiming(false);
                }
            });
    }

    const calculateTotalDailyEarnings = (data) => {
        let totalVal = 0;
        if (data && data.length > 0) {
            data.forEach(nft => {
                const dailyVal = dailyMaal[nft.seat] || 0;
                totalVal = totalVal + dailyVal;
            });
        }
        return totalVal;
    }

    useEffect(() => {
        if (myStars.data && myStars.data.length > 0) {
            const data = JSON.parse(JSON.stringify(myStars.data))
            setMyStarsNFTs(data);
            setTotalEarningDaily(calculateTotalDailyEarnings(data));
        }
    }, [myStars]);

    useEffect(() => {
        if (rewards.data) {
            const reward = Moralis.Units.FromWei(rewards.data);

            setClaimableRewards(Number(reward).toFixed(2));
        }
    }, [rewards]);



    return (
        <div className="greyscheme">
            <StyledHeader theme={theme} />
            <section className="container jumbotron no-bg">
                <div>
                    <div>
                        <div className="de-flex justify-content-center">
                            <h3>{t('my_stars')}</h3>
                        </div>
                    </div>
                </div>
                {
                    !isClaiming &&
                    <div className="de-flex justify-content-center align-items-center">
                        <div className="de-flex justify-content-center align-items-center mb30 mr30" style={{ flexDirection: 'column' }}>
                            <div>{t('accrued_rewards')}</div>
                            <div className="de-flex justify-content-center align-items-center" style={{ color: 'gold', fontSize: '1.5rem', flexDirection: 'column' }}>
                                <div>{claimableRewards} $MAAL</div> 
                                 <div >
                                <Button onClick={getMaalRewards} style={{ minWidth: '300px' }} className='btn-main lead mb-2 mr20'> {t('claim')}</Button>
                            </div>
                            </div>
                        </div>

                        <div className="de-flex justify-content-center align-items-center mb30 ml30" style={{ flexDirection: 'column' }}>
                            <div>{'Total Daily Earnings'}</div>
                            <div className="de-flex justify-content-center align-items-center" style={{ color: 'gold', fontSize: '1.5rem', flexDirection: 'column' }}>
                                <div>{totalEarningDaily} $MAAL</div>
                                {/* <div >
                                <Button onClick={getMaalRewards} style={{ minWidth: '300px' }} className='btn-main lead mb-2 mr20'> {t('claim')}</Button>
                            </div> */}
                            </div>
                        </div>
                    </div>

                }

                {
                    isClaiming && <>
                        <div style={{ height: '50vh', fontSize: '1rem', flexDirection: 'column', animation: "zoom-in-zoom-out 2s ease infinite" }} className="centered">
                            <div style={{ color: "#ffff" }}>
                                <div className="de-flex justify-content-center mb20">
                                    CLAIMING ...
                                </div>
                                <Image style={{ borderRadius: '50%' }} src={'https://i.kym-cdn.com/photos/images/original/001/001/589/b70.gif'} preview={false} />
                            </div>
                        </div>
                    </>
                }

                {
                    myStars.isLoading &&
                    <div style={{ height: '50vh', flexDirection: 'column', animation: "zoom-in-zoom-out 2s ease infinite" }} className="centered">
                        <Spin size="large" />
                        <div style={{ color: "#ffff" }}>
                            Loading...
                        </div>
                    </div>
                }

                {
                    isAuthenticated && account && !myStars.isLoading && myStarsNFTs.length > 0 && !isClaiming &&
                    <>
                        <div className="row">
                            {
                                myStarsNFTs.map((nft, i) => {
                                    return <div key={i}
                                        className="d-item col-lg-4 col-md-6 col-sm-6 col-xs-12 mb-4"
                                        style={{ maxWidth: 250 }}>
                                        <div className="nft__item m-0">
                                            <h3 style={{ marginBottom: '0' }}><a className='link' href={'https://nft.wazirx.org/assets/0xB382Fcd0263f4e338437F3e9DdB445063A818D6c/' + nft.token_id} target="_blank">Star <span style={{ textDecoration: "underline" }}>#{nft.token_id}</span></a></h3>
                                            <div className="nft__item_wrap mb0 mt0">
                                                <Outer>
                                                    <Image src={nft.image} id="vid" width="100%" height="250" preview={false} />
                                                </Outer>
                                            </div>
                                            <div className="nft__item_info" style={{ fontSize: '0.9rem' }}>
                                                {nft.seat} - {dailyMaal[nft.seat]} $MAAL/DAY
                                            </div>
                                        </div>
                                    </div>

                                })
                            }
                        </div>
                    </>
                }

            </section>
        </div>
    );
}
export default MyStars;
