import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import './assets/animated.css';
import '../node_modules/font-awesome/css/font-awesome.min.css';
import '../node_modules/elegant-icons/style.css';
import '../node_modules/et-line/style.css';
import '../node_modules/bootstrap/dist/css/bootstrap.min.css';
import '../node_modules/bootstrap/dist/js/bootstrap.js';
import '../node_modules/antd/dist/antd.css';
import './assets/style.scss';
import './assets/style_grey.scss';
import App from './components/app';
import * as serviceWorker from './serviceWorker';
import { MoralisProvider } from 'react-moralis';
import { MoralisAPIProvider } from './hooks/useMoralisAPI';
//redux store
import { Provider } from 'react-redux';
import store from './store';

import './i18n';
import { ToasterProvider } from './components/components/Toast';
import ToastContainer from './components/components/Toast/ToastContainer';

const APP_ID = process.env.REACT_APP_MORALIS_APPLICATION_ID;
const SERVER_URL = process.env.REACT_APP_MORALIS_SERVER_URL;

// Will need to change loader for fallback
ReactDOM.render(
  <Suspense fallback="loading">
    <ToasterProvider>
      <MoralisProvider appId={APP_ID} serverUrl={SERVER_URL}>
        <MoralisAPIProvider>
          <Provider store={store}>
            <App />
            <ToastContainer />
          </Provider>
        </MoralisAPIProvider>
      </MoralisProvider>
    </ToasterProvider>
  </Suspense>,
  document.getElementById('root')
);
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
