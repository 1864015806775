import React, {useEffect} from 'react';
import useToaster from '../Toast';
import './style.scss';

const Toast = ({ toast }) => {
    const { deleteToast } = useToaster();

    const handleDelete = () => {
        deleteToast(toast.id);
    };

    useEffect(() => {
        const timer = setTimeout(() => {
            handleDelete()
        }, toast.timeout);

        return () => {
            clearTimeout(timer);
        };
    }, []);

    const getClassName = () => {
        switch (toast.type) {
            case 'error':
                return 'alert-danger';
            case 'success':
                return 'alert-success';
            case 'warning':
                return 'alert-warning';
            case 'info':
            default:
                return 'alert-info';
        }
    }

    return (
        <div className={`d-flex justify-content-center  align-items-center px-3 py-1 alert ${getClassName()}`} role="alert">
            <span className="mr20">{toast.message}</span>
            <span aria-hidden="true" className="icon_close ml10 fs-4" role="button" onClick={handleDelete} />
        </div>
    )
}

export default Toast;
