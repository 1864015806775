/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import Select from '../components/Select';
import { StyledHeader } from '../Styles';
import useMoralisAPI from '../../hooks/useMoralisAPI';
import MaalCard from '../components/MaalCard';
import { createOptions } from '../../utils';
import { useTranslation } from 'react-i18next';
import { useWeb3ExecuteFunction, useMoralis } from 'react-moralis';
import config from '../../config/config';
import { Image, Spin } from 'antd';
import useToaster from "../components/Toast";


//SWITCH VARIABLE FOR PAGE STYLE
const theme = 'GREY'; //LIGHT, GREY, RETRO

const silver = 50;
const gold = 100;
const roseGold = 250;
const black = 500;

const MintMalaMaal = () => {
  const { Moralis, account } = useMoralis();
  const { toast } = useToaster();
  const { contractABI, malaMaalContractABI, maalContractABI,
    isActive, maalBalance, getMaalBalance, allowance, getMaalAllowance, getMalaMaalNFTs } = useMoralisAPI();

  const [qty, setQty] = useState(0);
  const [isMinting, setIsMinting] = useState(false);
  const [allowanceNumber, setAllowanceNumber] = useState(0);
  const [cardType, setCardType] = useState(0);
  const [isApproving, setIsApproving] = useState(false);
  const [mintClicked, setMintClicked] = useState(false);

  const { data: dataMalamal, error: errorMalamal, fetch: mintMalamaal,
    isFetching: isFetchingMalamal, isLoading: isLoadMalamal } = useWeb3ExecuteFunction({
      abi: malaMaalContractABI,
      contractAddress: config[config.network].contract_maalaamal,
      functionName: "mint",
      params: {
        qty: qty,
        cardType: cardType
      },
      msgValue: 0
    });

  const { data: successAproveMaal, error: errorApproveMaal, fetch: approveMaal,
    isLoading: isLoadingApprove } = useWeb3ExecuteFunction({
      abi: maalContractABI,
      contractAddress: config[config.network].contract_maal,
      functionName: "approve",
      params: {
        spender: config[config.network].contract_maalaamal,
        // amount: "0"
        amount: "1000000000000000000000000000"
      }
    });


  const { t } = useTranslation();

  function approveMaalToken() {
    setIsApproving(true);
    approveMaal(
      {
        onSuccess: async (txn) => {
          const transaction = await txn.wait();
          setAllowanceNumber(100000000);
          setIsApproving(false);
          getMaalAllowance();
          // get Maal balance
          toast('success', t('success_approval_msg'));
        },
        onError: (error) => {
          console.log("on error", error);
          setIsApproving(false);
        }
      });
  }

  const handleSubmit = async (value, id) => {
    setCardType(id);
    setQty(value.value);
    setMintClicked(true);
  };

  const optionsSilver = createOptions(maalBalance, silver);
  const optionsGold = createOptions(maalBalance, gold);
  const optionsRoseGold = createOptions(maalBalance, roseGold);
  const optionsBlack = createOptions(maalBalance, black);


  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(async () => {
    if (mintClicked) {
      setIsMinting(true);
      mintMalamaal(
        {
          onSuccess: async (txn) => {
            const transaction = await txn.wait();
            setIsMinting(false);
            // get Maal balance
            getMaalBalance();
            getMalaMaalNFTs();
            setMintClicked(false);
            toast('success', t('success_mint_msg'));
          },
          onError: (error) => {
            console.log("on error", error);
            if(error.data && 
              error.data.message &&
              error.data.message.includes('User should hold minimum number of stars')){
                toast('error', 'You must own atleaset 1 star to mint!');
            }
            setIsMinting(false);
            setMintClicked(false);
          }
        }
      );
    }
  }, [mintClicked]);

  useEffect(async () => {
    if (errorMalamal) {
      setMintClicked(false);
    }
  }, [errorMalamal]);

  useEffect(async () => {
    if (allowance.data) {
      const allowanceNum = Moralis.Units.FromWei(allowance.data);
      setAllowanceNumber(allowanceNum);
    }
  }, [allowance.data]);


  return (
    <div className="greyscheme">
      <StyledHeader theme={theme} />
      <section className="de-flex flex-column align-items-center">
        <div className="row container">
          <div>
            <div>
              <div className="de-flex justify-content-center mb30">
                <h3>{t('mint_malamaal')}</h3>
              </div>
            </div>
          </div>

          {(allowance.isFetching ||
            isActive.isFetchingMalaMintActive) &&
            <>
              <div style={{ height: '50vh', flexDirection: 'column', animation: "zoom-in-zoom-out 2s ease infinite" }} className="centered">
                <Spin size="large" />
                <div style={{ color: "#ffff" }}>
                  Loading...
                </div>
              </div>

            </>
          }

          {isActive.isMalaMintActive && isApproving &&
            <>
              <div style={{ height: '50vh', flexDirection: 'column', animation: "zoom-in-zoom-out 2s ease infinite" }} className="centered">
                <Spin size="large" />
                <div style={{ color: "#ffff" }}>
                  Approval in progress
                </div>
              </div>

            </>
          }

          {isActive.isMalaMintActive && isMinting &&
            <>
              <div style={{ height: '40vh', flexDirection: 'column', animation: "zoom-in-zoom-out 2s ease infinite" }} className="centered">
                {/* <Spin size="large" /> */}
                <div className="de-flex justify-content-centered mb10">
                  Manufactoring your card...
                </div>
                <video id="vid" width="460" height="300" autoPlay loop>
                  {cardType === 'silver' && <source src={config.cards.manufacturing.silver} />}
                  {cardType === 'gold' && <source src={config.cards.manufacturing.gold} />}
                  {cardType === 'rose' && <source src={config.cards.manufacturing.rose} />}
                  {cardType === 'black' && <source src={config.cards.manufacturing.black} />}
                </video>
              </div>

            </>
          }
          {
            isActive.isMalaMintActive && !isMinting && !isApproving
            && !allowance.isFetching && !(allowanceNumber > 0) &&
            <>
              <div className="centered" style={{ height: '50vh' }}>
                <div className="connect-wal centered" style={{ flexDirection: 'column' }}>
                  <div className="mb30">
                    {t('please_approve_msg')}
                  </div>
                  <a>
                    <span onClick={() => approveMaalToken()}>
                      {t('approve_maal')}
                    </span>
                  </a>
                </div>
              </div>

            </>
          }
          {
            isActive.isMalaMintActive && !allowance.isFetching &&
              !isActive.isFetchingMalaMintActive && !isMinting && allowanceNumber > 0 ?
              <div className="de-flex flex-column">
                <div className="row">
                  <MaalCard
                    video={config.cards.links.silver}
                    title="Silver"
                    id="silver"
                    options={optionsSilver}
                    onSubmit={handleSubmit}
                  />
                  <MaalCard
                    video={config.cards.links.gold}
                    title="Gold"
                    id="gold"
                    options={optionsGold}
                    onSubmit={handleSubmit}
                  />
                  <MaalCard
                    video={config.cards.links.rose}
                    title="Rose gold"
                    id="rose"
                    options={optionsRoseGold}
                    onSubmit={handleSubmit}
                  />
                  <MaalCard
                    video={config.cards.links.black}
                    title="Black"
                    id="black"
                    options={optionsBlack}
                    onSubmit={handleSubmit}
                  />
                </div>
              </div>
              :
              !isMinting && !isActive.isMalaMintActive && !isActive.isFetchingMalaMintActive && !allowance.isFetching &&
              <div style={{ height: '70vh' }} className="centered">
                <div className="alert alert-warning" role="alert">
                  Minting is not active!
                </div>
              </div>
          }


        </div>
      </section>
    </div>
  );
};

export default MintMalaMaal;
