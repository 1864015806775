import { useEffect, useState } from "react";
import { useMoralisWeb3Api, useMoralisWeb3ApiCall, useMoralis } from "react-moralis";
import config from "../config/config";

export const useNFTBalance = (options, isMalaMaal) => {
    const { account } = useMoralisWeb3Api();
    const { chainId } = useMoralis();
    const [NFTBalance, setNFTBalance] = useState([]);
    const {
        fetch: getNFTBalance,
        data,
        error,
        isLoading,
    } = useMoralisWeb3ApiCall(account.getNFTsForContract, { chain: chainId, ...options });

    function compare(a, b) {
        const metadataA = JSON.parse(a.metadata);
        const metadataB = JSON.parse(b.metadata);

        if (!metadataA || !metadataB) {
            return -1;
        }
        const priceAttribA = metadataA.attributes.filter(x => x.trait_type === 'Price');
        const priceAttribB = metadataB.attributes.filter(x => x.trait_type === 'Price');

        if (Number(priceAttribA[0].value) < Number(priceAttribB[0].value)) {
            return -1;
        }
        if (Number(priceAttribA[0].value) > Number(priceAttribB[0].value)) {
            return 1;
        }
        return 0;
    }

    function sortFromLowToHigh(nftData) {
        nftData.sort(compare);
    }

    useEffect(() => {
        if (data) {
            data.result.forEach((nft, i) => {
                if (nft.metadata) {
                    const metadata = JSON.parse(nft.metadata)
                    nft.name = metadata.name
                    if (metadata.image && metadata.image.length && metadata.image.includes('https://ipfs.io/ipfs')) {
                        nft.image = metadata.image
                    } else if (metadata.image && metadata.image.length && metadata.image.includes('ipfs://')) {
                        nft.image = `https://ipfs.io/ipfs/${(metadata.image).split('ipfs://')[1]}`
                    } else {
                        nft.image = metadata.image
                    }

                    if (!isMalaMaal) {
                        const seat = metadata.attributes.filter(x => x.trait_type === 'Seat');
                        if (seat && seat.length > 0) {
                            nft.seat = seat[0].value;
                        }
                    }

                    // set type of malamaal card
                    if (isMalaMaal && metadata && metadata.name) {
                        if (metadata.name.includes('Silver')) {
                            nft.type = 'silver';
                            nft.image_url = config.cards.links.silver;
                        } else if (metadata.name.includes('Rose')) {
                            nft.type = 'rose';
                            nft.image_url = config.cards.links.rose;
                        } else if (metadata.name.includes('Gold')) {
                            nft.type = 'gold';
                            nft.image_url = config.cards.links.gold;
                        } else if (metadata.name.includes('Black')) {
                            nft.type = 'black';
                            nft.image_url = config.cards.links.black;
                        }
                    }
                } else {
                    // delete data.result[i]
                }

            })
            if (isMalaMaal) sortFromLowToHigh(data.result);
            setNFTBalance(data.result)

        }
    }, [data]);

    useEffect(() => {
        if (chainId) {
            getNFTBalance()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [chainId])

    return { getNFTBalance, NFTBalance, error, isLoading };
};
