import React, { useState } from 'react';
import styled from 'styled-components';
import Select from './Select';
import { useTranslation } from 'react-i18next';

const Outer = styled.div`
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
  overflow: hidden;
  border-radius: 8px;
`;

const MaalCard = ({ title, options, onSubmit, id, video }) => {
  const [value, setValue] = useState();
  const { t } = useTranslation();
  const handleSubmit = () => {
    onSubmit(value, id);
  };

  return (
    <div
      className="d-item col-lg-4 col-md-6 col-sm-6 col-xs-12 mb-4"
      style={{ maxWidth: 300 }}
    >
      <div className="nft__item m-0">
        <h3 style={{ marginBottom: '0' }}>{title}</h3>
        <div className="nft__item_wrap">
          <Outer>
            <video id="vid" width="100%" height="250" autoPlay loop>
              <source src={video} />
            </video>
          </Outer>
        </div>
        <div className="nft__item_info">
          <div className="de-flex w-100 py-2">
            <div className="w-100 mr20">
              <Select options={options} value={value} onChange={setValue} />
            </div>
            <input
              type="button"
              onClick={handleSubmit}
              disabled={!value}
              id="submit"
              className="btn-main"
              value={t('mint')}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default MaalCard;
