import React from "react";
import moment from "moment";
import hiLocale from "moment/locale/hi";
import enInLocale from "moment/locale/en-in";

// import language here whatever our platform support
moment.locale('hi', [hiLocale]);

const Duration = ({ date = new Date() }) => {
    moment.locale('en', [hiLocale, enInLocale])
    return moment(date).fromNow();
};

export default Duration;