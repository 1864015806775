const config = {
    network: 'mainnet',
    testnet: {
        chainId: "0x61",
        contract_css: "0x5B76385aeA60F45991B72E0CBbd4c75597024699",
        contract_maalaamal: "0xeA5f48e41C6cBB9cc3c4912497f8588943387b8B",
        contract_maal: "0x1D45e5374B4a642F2619958a5391c15c63A37258"
    },
    mainnet: {
        chainId: "0x38",
        contract_css: "0xB382Fcd0263f4e338437F3e9DdB445063A818D6c",
        contract_maalaamal: "0xFE692ADD44b90889D5cF7397e179E6240B7799c8",
        contract_maal: "0x21A14970Cad64d054b9E3b54299493dB762f9f1f"
    },
    cards: {
        links: {
            silver: "https://res.cloudinary.com/cryptosauga/video/upload/v1645491687/malamaal/silver.mp4",
            gold: "https://res.cloudinary.com/cryptosauga/video/upload/v1645491687/malamaal/gold.mp4",
            rose: "https://res.cloudinary.com/cryptosauga/video/upload/v1645491687/malamaal/rose.mp4",
            black: "https://res.cloudinary.com/cryptosauga/video/upload/v1645491687/malamaal/black.mp4"
        },
        manufacturing: {
            silver: "https://res.cloudinary.com/cryptosauga/video/upload/v1645491687/malamaal/silver-m.mp4",
            gold: "https://res.cloudinary.com/cryptosauga/video/upload/v1645491687/malamaal/gold-m.mp4",
            rose: "https://res.cloudinary.com/cryptosauga/video/upload/v1645491687/malamaal/rose-m.mp4",
            black: "https://res.cloudinary.com/cryptosauga/video/upload/v1645491687/malamaal/black-m.mp4"
        }
    }
}

export default config;