import Select, { components } from 'react-select';
import React from 'react';

const customStyles = {
  option: (base, state) => ({
    ...base,
    background: '#fff',
    color: '#727272',
    borderRadius: state.isFocused ? '0' : 0,
    '&:hover': {
      background: '#ddd',
    },
  }),
  menu: (base) => ({
    ...base,
    background: '#fff !important',
    borderRadius: 0,
    marginTop: 0,
  }),
  menuList: (base) => ({
    ...base,
    padding: 0,
  }),
  control: (base, state) => ({
    ...base,
    padding: 2,
  }),
};

const NoOptionsMessage = (props) => {
  const { text = 'No options', ...rest } = props;
  return (
    <components.NoOptionsMessage {...rest}>
      <span className="custom-css-class">{text}</span>
    </components.NoOptionsMessage>
  );
};

const CustomSelect = ({ options, noOptionsPlaceholder, ...rest }) => {
  return (
    <Select
      components={{
        NoOptionsMessage: (props) => (
          <NoOptionsMessage text={noOptionsPlaceholder} {...props} />
        ),
      }}
      className="select"
      styles={customStyles}
      menuContainerStyle={{ zIndex: 999 }}
      options={options}
      {...rest}
    />
  );
};

export default CustomSelect;
