import React, { createContext, useContext, useEffect, useState } from 'react';
import CSS_ABI from '../abi/abi_css.json';
import MAALA_MAAL_ABI from '../abi/abi_maalamaal.json';
import MAAL_ABI from '../abi/abi_maal.json';
import { useMoralis, useWeb3ExecuteFunction } from 'react-moralis';
import config from '../config/config';
import useToaster from '../components/components/Toast';
import { useNFTBalance } from './useNftBalance';

const MoralisAPIContext = createContext();

export function MoralisAPIProvider({ children }) {
  const mixpanel = useMoralisAPIPorvider();
  return (
    <MoralisAPIContext.Provider value={mixpanel}>
      {children}
    </MoralisAPIContext.Provider>
  );
}

function useMoralisAPIPorvider() {
  const [contractABI, setCssContractABI] = useState(null); //Smart Contract ABI here
  const [malaMaalContractABI, setMalaMaalContractABI] = useState(null); //Smart Contract ABI here
  const [maalContractABI, setMaalContractABI] = useState(null); //Smart Contract ABI here
  const [tokenIdOptions, setTokenIdOptions] = useState(null);
  const [maalBalance, setMaalBalance] = useState(0);
  const [tokenIds, setTokenIds] = useState(0);
  const [isBinanceNetwork, setIsBinanceNetwork] = useState(false);
  const { Moralis, account, enableWeb3, isAuthenticated, chainId, isWeb3Enabled, isWeb3EnableLoading } = useMoralis();
  const { NFTBalance: NFTBalanceM_MMC, isLoading: isLoadingMalaMaalCard, getNFTBalance: getNFTBalance_MMC } = useNFTBalance({ token_address: config[config.network].contract_maalaamal, address: account }, true);
  const { NFTBalance: NFTBalanceM_CSSC, isLoading: isLoadingCSSC, getNFTBalance: getNFTBalance_CSSC } = useNFTBalance({ token_address: config[config.network].contract_css, address: account });
  const cardMapper = {
    'silver': 50,
    'gold': 100,
    'rose': 250,
    'black': 500
  };
  const [isApproved, setIsApproved] = useState(false);
  const {
    data: successAllowanceMaal,
    error: errorAllowanceMaal,
    fetch: allowanceMaal,
    isFetching: isFetchingAllowanceMaal,
    isLoading,
  } = useWeb3ExecuteFunction({
    abi: maalContractABI,
    contractAddress: config[config.network].contract_maal,
    functionName: 'allowance',
    params: {
      owner: account,
      spender: config[config.network].contract_maalaamal,
    },
  });

  const {
    data: succMaalBal,
    error: errMaalBal,
    fetch: getMaalBalance,
    isFetching: isFetchingMaalBal,
    isLoading: isLoadMaalBal,
  } = useWeb3ExecuteFunction({
    abi: maalContractABI,
    contractAddress: config[config.network].contract_maal,
    functionName: 'balanceOf',
    params: {
      account: account,
    },
  });

  const {
    data: succGetClaimable,
    error: errGetClaimable,
    fetch: getClaimableReward,
    isFetching: isFetchingGetClaimable,
    isLoading: isLoadGetClaimable,
  } = useWeb3ExecuteFunction({
    abi: maalContractABI,
    contractAddress: config[config.network].contract_maal,
    functionName: 'getClaimableReward',
    params: {
      _tokenIds: tokenIds,
    },
  });

  // cSSC
  const {
    data: isMintWithTknActive,
    error: errIsMintWithTknActive,
    fetch: checkIfMintWithTknActive,
    isFetching: fetchingIsMintWithTknActive,
    isLoading: loadingIsMintWithTknActive,
  } = useWeb3ExecuteFunction({
    abi: contractABI,
    contractAddress: config[config.network].contract_css,
    functionName: 'isMintWithTknActive'
  });


  const {
    data: successTokensOfOwner,
    error: errorTokensOfOwner,
    fetch: getTokensOfOwner,
    isFetching: isFetchingTokensOfOwner,
    isLoading: isLoadTokensOfOwner,
  } = useWeb3ExecuteFunction({
    abi: malaMaalContractABI,
    contractAddress: config[config.network].contract_css,
    functionName: 'tokensOfOwner',
    params: {
      _owner: account,
    },
  });

  // check if mint star is active
  const {
    data: successIsMalaMintActive,
    error: errorIsMalaMint,
    fetch: getIsMalMintActive,
    isFetching: isFetchingMalaMintActive,
    isLoading: isLoadMalaMintActive
  } = useWeb3ExecuteFunction({
    abi: malaMaalContractABI,
    contractAddress: config[config.network].contract_maalaamal,
    functionName: 'isActive'
  });

  // check if mint malamaal active
  const {
    data: successIsRedeemActive,
    error: errorIsRedeemActive,
    fetch: getIsRedeemActive,
    isFetching: isFetchingRedeemActive,
    isLoading: isLoadRedeemActive
  } = useWeb3ExecuteFunction({
    abi: malaMaalContractABI,
    contractAddress: config[config.network].contract_maalaamal,
    functionName: 'isRedeemActive'
  });


  useEffect(() => {
    const connectorId = window.localStorage.getItem('connectorId');
    if (isAuthenticated && !isWeb3Enabled && !isWeb3EnableLoading)
      enableWeb3({ provider: connectorId });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated, isWeb3Enabled]);

  useEffect(() => {
    if (chainId) {
      setIsBinanceNetwork(chainId === config[config.network].chainId); //TODO - get this from config
    }
  }, [chainId]);

  useEffect(() => {
    setMalaMaalContractABI(MAALA_MAAL_ABI);
    setCssContractABI(CSS_ABI);
    setMaalContractABI(MAAL_ABI);
    allowanceMaal();
    getMaalBalance();
    getIsRedeemActive();
    getIsMalMintActive();
    checkIfMintWithTknActive();
    setIsBinanceNetwork(chainId === config[config.network].chainId);
  }, [isWeb3Enabled, chainId]);


  useEffect(() => {
    getTokensOfOwner();
  }, [account]);


  useEffect(() => {
    if (successAllowanceMaal) {
      const allwance = Moralis.Units.FromWei(successAllowanceMaal);
      if (allwance > 0) {
        setIsApproved(true);
      }
    }
  }, [successAllowanceMaal]);

  useEffect(() => {
    if (succMaalBal) {
      const balance = Moralis.Units.FromWei(succMaalBal);
      const balWith2Decimals = balance.toString().match(/^-?\d+(?:\.\d{0,2})?/)[0]
      setMaalBalance(balWith2Decimals);
    }
  }, [succMaalBal]);

  useEffect(() => {
    if (successTokensOfOwner) {
      let options = [];
      let tokenIds = [];
      successTokensOfOwner.forEach((bigNumber) => {
        const tokenId = Moralis.Units.FromWei(bigNumber, 0);
        options.push({
          value: tokenId,
          label: tokenId,
        });
        tokenIds.push(tokenId);
      });
      setTokenIds(tokenIds);
      // call getclaimablerewards

      setTokenIdOptions(options);
    }
  }, [successTokensOfOwner]);

  useEffect(() => {
    if (tokenIds && tokenIds.length > 0) {
      getClaimableReward(tokenIds);
    }
  }, [tokenIds]);



  return {
    contractABI,
    malaMaalContractABI,
    maalContractABI,
    isBinanceNetwork: isBinanceNetwork,
    isActive: {
      isFetchingMalMintActive: isFetchingMalaMintActive,
      isRedeemActive: successIsRedeemActive,
      isMalaMintActive: successIsMalaMintActive,
      isMintWithTokenActive: isMintWithTknActive
    },
    cardMapper: cardMapper,
    myStars: {
      data: NFTBalanceM_CSSC,
      isLoading: isLoadingCSSC
    },
    rewards: {
      data: succGetClaimable,
      isLoading: isLoadGetClaimable
    },
    malaMaal: {
      data: NFTBalanceM_MMC,
      isLoading: isLoadingMalaMaalCard
    },
    allowance: {
      data: successAllowanceMaal,
      error: errorAllowanceMaal,
      isLoading: isLoading,
      isFetching: isFetchingAllowanceMaal,
    },
    balanceOf: {
      data: succMaalBal,
      error: errMaalBal,
      isLoading: isLoadMaalBal,
      isFetching: isFetchingMaalBal,
    },
    tokensOfOwner: {
      data: successTokensOfOwner,
      error: errorTokensOfOwner,
      isLoading: isLoadTokensOfOwner,
      isFetching: isFetchingTokensOfOwner,
    },
    isApproved,
    maalBalance,
    getMaalBalance,
    getTokensOfOwner,
    getMalaMaalNFTs: getNFTBalance_MMC,
    getMyStars: getNFTBalance_CSSC,
    getMaalAllowance: allowanceMaal,
    getClaimableReward,
    tokenIdOptions,
  };
}

const useMoralisAPI = () => useContext(MoralisAPIContext);

export default useMoralisAPI;
