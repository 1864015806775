import { useMoralis, useChain } from 'react-moralis';
import { useTranslation } from 'react-i18next';
import { getEllipsisTxt } from '../helpers/formatters';
import Blockie from '../Blockie';
import { Button, Card, Modal } from 'antd';
import { useState, useEffect } from 'react';
import Address from '../Address/Address';
import { SelectOutlined } from '@ant-design/icons';
import { getExplorer } from '../helpers/networks';
import Text from 'antd/lib/typography/Text';
import { connectors } from './config';
import './style.scss';
import useMoralisAPI from '../../../hooks/useMoralisAPI';
import useToaster from "../../components/Toast";
import config from '../../../config/config';

const styles = {
  account: {
    height: '42px',
    padding: '0 15px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: 'fit-content',
    borderRadius: '12px',
    backgroundColor: 'rgb(244, 244, 244)',
    cursor: 'pointer',
  },
  text: {
    color: '#21BF96',
  },
  connector: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    height: 'auto',
    justifyContent: 'center',
    marginLeft: 'auto',
    marginRight: 'auto',
    padding: '20px 5px',
    cursor: 'pointer',
  },
  icon: {
    alignSelf: 'center',
    fill: 'rgb(40, 13, 95)',
    flexShrink: '0',
    marginBottom: '8px',
    height: '30px',
  },
};

function Account() {
  const { authenticate, isAuthenticated, account, chainId, logout } =
    useMoralis();
  const { switchNetwork } = useChain();

  const { isBinanceNetwork } = useMoralisAPI();
  const { t } = useTranslation();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isAuthModalVisible, setIsAuthModalVisible] = useState(false);
  const { toast } = useToaster();


  useEffect(() => {
    if (chainId && chainId !== config[config.network].chainId && isAuthenticated && !isBinanceNetwork) {
      toast('error', 'Please connect to binance network!');
    }
  }, [account, chainId]);

  if (!isAuthenticated || !account) {
    return (
      <div className="connect-wal">
        <a>
          <span onClick={() => setIsAuthModalVisible(true)}>
            {t('connect_wallet')}
          </span>
          <Modal
            visible={isAuthModalVisible}
            footer={null}
            onCancel={() => setIsAuthModalVisible(false)}
            bodyStyle={{
              padding: '15px',
              fontSize: '17px',
              fontWeight: '500',
            }}
            style={{ fontSize: '16px', fontWeight: '500' }}
            width="340px"
          >
            <div
              style={{
                padding: '10px',
                display: 'flex',
                justifyContent: 'center',
                fontWeight: '700',
                fontSize: '20px',
              }}
            >
              {t('connect_wallet')}
            </div>
            <div style={{ display: 'grid', gridTemplateColumns: '1fr 1fr' }}>
              {connectors.map(({ title, icon, connectorId }, key) => (
                <div
                  style={styles.connector}
                  key={key}
                  onClick={async () => {
                    try {
                      await authenticate({ signingMessage: "CSSC Autentication", provider: connectorId });
                      window.localStorage.setItem('connectorId', connectorId);
                      setIsAuthModalVisible(false);
                    } catch (e) {
                      console.error(e);
                    }
                  }}
                >
                  <img src={icon} alt={title} style={styles.icon} />
                  <Text style={{ fontSize: '14px' }}>{title}</Text>
                </div>
              ))}
            </div>
          </Modal>
        </a>
      </div>
    );
  }

  if (!isBinanceNetwork) {
    return (
      <div className="connect-wal">
        <a>
          <span onClick={() => switchNetwork(config[config.network].chainId)}>
            {'Switch to Binance Network'}
          </span>
        </a>
      </div>
    )
  }

  return (
    <div className="connect-wal">
      <a>
        {/* <button
        onClick={async () => {
          try {
            console.log("change")
            await web3._provider.request({
              method: "wallet_switchEthereumChain",
              params: [{ chainId: "0x38" }],
            });
            console.log("changed")
          } catch (e) {
            console.error(e);
          }
        }}
      >
        Hi
      </button> */}
        <span onClick={() => setIsModalVisible(true)}>
          <Blockie currentWallet scale={3} />
          <span style={{ marginLeft: '5px' }}>{getEllipsisTxt(account, 6)}</span>
          {/* <p style={{ marginRight: "5px", ...styles.text }}>
          {getEllipsisTxt(account, 6)}
        </p> */}
        </span>
        <Modal
          visible={isModalVisible}
          footer={null}
          onCancel={() => setIsModalVisible(false)}
          bodyStyle={{
            padding: '15px',
            fontSize: '17px',
            fontWeight: '500',
          }}
          style={{ fontSize: '16px', fontWeight: '500' }}
          width="400px"
        >
          {t('account')}
          <Card
            style={{
              marginTop: '10px',
              borderRadius: '1rem',
            }}
            bodyStyle={{ padding: '15px' }}
          >
            <Address
              avatar="left"
              size={6}
              copyable
              style={{ fontSize: '20px' }}
            />
            <div style={{ marginTop: '10px', padding: '0 10px' }}>
              <a
                href={`${getExplorer(chainId)}/address/${account}`}
                target="_blank"
                rel="noreferrer"
              >
                <SelectOutlined style={{ marginRight: '5px' }} />
                {t('view_on_explorer')}
              </a>
            </div>
          </Card>
          <Button
            size="large"
            type="danger"
            style={{
              width: '100%',
              marginTop: '10px',
              borderRadius: '0.5rem',
              fontSize: '16px',
              fontWeight: '500',
            }}
            onClick={async () => {
              await logout();
              window.localStorage.removeItem('connectorId');
              setIsModalVisible(false);
            }}
          >
            {t('disconnect_wallet')}
          </Button>
        </Modal>
      </a>
    </div>
  );
}

export default Account;
