import React from "react";
import useToaster from "./index";
import Toast from "./Toast";

const Toaster = () => {
    const { toastList } = useToaster();
    return (
        <>
            <div className={`notification-container top-center`}>
                {toastList.map(toast => <Toast toast={toast} key={toast.id} />)}
            </div>
        </>
    );
};
export default Toaster;