import React, { createContext, useContext, useEffect, useState, useCallback } from 'react';

const ToasterContext = createContext();

export function ToasterProvider({ children }) {
    const provider = useToasterProvider();
    return <ToasterContext.Provider value={provider}>{children}</ToasterContext.Provider>;
}

function useToasterProvider() {
    const [toastList, setToastList] = useState([]);

    const deleteToast = useCallback((id) => {
        setToastList(toasts => toasts.filter(t => t.id !== id));
    }, [setToastList]);

    const toast = useCallback((type = 'success', message = '', timeout = 3300) => {
        const id = Math.random().toString(36).substr(2, 9);
        setToastList([...toastList, { message, type, id, timeout }]);
    }, [setToastList]);

    return {
        toast,
        deleteToast,
        toastList,
    };
}

export default () => useContext(ToasterContext);


